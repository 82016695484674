




import KText from '@/@core/components/typography/KText.vue';
import { useNumberToCurrency } from '@/@core/helpers/formatter';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: { KText },
  props: {
    value: {
      type: Number as PropType<number>,
      required: true
    }
  },
  name: 'TableColumnCurrencyFormat',
  setup() {
    //

    return {
      useNumberToCurrency
    };
  }
});
