







import KText from '@/@core/components/typography/KText.vue';
import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  components: { KText },
  props: {
    item: {
      type: Object as PropType<any>,
      required: true
    }
  },
  name: 'TableColumnName',
  setup() {
    //

    return {
      //
    };
  }
});
