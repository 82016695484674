





























































































import KComboboxInput from '@/@core/components/input/KComboboxInput.vue';
import KFileInput from '@/@core/components/input/KFileInput.vue';
import KTextInput from '@/@core/components/input/KTextInput.vue';
import KHamburgerMenu from '@/@core/components/navigation/KHamburgerMenu.vue';
import KCard from '@/@core/components/other/KCard.vue';
import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KPageHeader from '@/@core/components/page/KPageHeader.vue';
import KTableActions from '@/@core/components/table/KTableActions.vue';
import KTableSearch from '@/@core/components/table/KTableSearch.vue';
import KTableWrapper from '@/@core/components/table/KTableWrapper.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import { useUiStateStore } from '@/@core/services/uiStateStore';
import {
  defineComponent,
  Ref,
  ref,
  unref
} from '@vue/composition-api';

import readXlsxFile from 'read-excel-file';
import { useIndustryStore } from '../../services/store';
import { useDistrictStore } from '@/modules/district/services/store';
import IndustryCurrencyFormat from '../../ui/table/IndustryCurrencyFormat.vue';
import IndustryName from '../../ui/table/IndustryName.vue';
import * as dataTransform from './industryDataTransform';

export default defineComponent({
  components: {
    KPage,
    KPageHeader,
    KPageBody,
    KWrapper,
    KHamburgerMenu,
    KText,
    KCard,
    KFileInput,
    KTableSearch,
    KTableActions,
    KTableWrapper,
    KTextInput,
    IndustryName,
    IndustryCurrencyFormat,
    KComboboxInput
  },
  name: 'IndustryImportPage',
  setup() {
    const dataStartFromRow = ref(1);
    const store = useIndustryStore();
    const districtStore = useDistrictStore();
    const resultJson: Ref<any[]> = ref([]);
    const isLoading = ref(false);
    const loadedExcel: Ref<unknown> = ref(null);
    const selectedDistrict = ref(null);
    const search = ref(null);
    const headers = [
      {
        text: 'Nama Industri',
        value: 'name'
      },
      {
        text: 'Alamat Industri',
        value: 'address'
      },
      //   {
      //     text: 'Titik Lokasi',
      //     value: 'geolocation'
      //   },
      {
        text: 'NPWP',
        value: 'npwp'
      },
      //   {
      //     text: 'Penanggung Jawab',
      //     value: 'inChargeName'
      //   },
      //   {
      //     text: 'Pemilik',
      //     value: 'ownerName'
      //   },
      {
        text: 'Badan Usaha',
        value: 'businessEntity'
      },
      //   {
      //     text: 'Nomor Telepon',
      //     value: 'phoneNumber'
      //   },
      {
        text: 'KBLI',
        value: 'classification'
      },
      //   {
      //     text: 'Perizinan',
      //     value: 'permissions'
      //   },
      //   {
      //     text: 'Sosial Media',
      //     value: 'socialMedias'
      //   },
      //   {
      //     text: 'Tenaga Kerja',
      //     value: 'employees'
      //   },
      {
        text: 'Nilai Investasi',
        value: 'totalInvestmentValue'
      },
      {
        text: 'Biaya Bahan Baku',
        value: 'totalCostsPerMonth'
      },
      {
        text: 'Produk',
        value: 'tags'
      }
    ];

    const successImportedData: Ref<any[]> = ref([]);
    const errorImportedData: Ref<any[]> = ref([]);

    const convert = () => {
      resultJson.value = [];

      if (!loadedExcel.value) return;

      const file = loadedExcel.value as File;

      readXlsxFile(file).then((rows) => {
        let data: any[] = [];

        rows.map((row: any) => {
          const isInvalidData = !dataTransform.name(row[1]);
          if (isInvalidData) return;

          const object = {
            name: dataTransform.name(row[1]),
            address: dataTransform.address(row[2]),
            geolocation: dataTransform.geolocation(row[3]),
            npwp: dataTransform.npwp(row[4]),
            inChargeName: dataTransform.inChargeName(row[5]),
            ownerName: dataTransform.ownerName(row[6]),
            businessEntity: dataTransform.businessEntity(row[7]),
            phoneNumber: dataTransform.phoneNumber(row[8]),
            classification: dataTransform.classification(row[9]),
            permissions: dataTransform.permissions(row[10]),
            socialMedias: dataTransform.socialMedias(row[11]),
            employees: dataTransform.employees({
              male: row[12],
              female: row[13]
            }),
            totalInvestmentValue: dataTransform.totalInvestmentValue(
              row[15]
            ),
            totalCostsPerMonth: dataTransform.totalCostsPerMonth(
              row[16]
            ),
            tags: dataTransform.tags(row[17]),
            products: dataTransform.products({
              names: row[17],
              capacityPerYears: row[18],
              units: row[19],
              pricePerUnits: row[20]
            }),
            district: dataTransform.district(selectedDistrict.value)
          };

          data.push(object);
        });

        data = data.slice(dataStartFromRow.value);

        resultJson.value = data;
      });
    };

    const onSubmit = async () => {
      isLoading.value = true;
      successImportedData.value = [];
      errorImportedData.value = [];

      let importedData = 0;
      const totalData = [...resultJson.value].length;

      for (const industryData of resultJson.value) {
        const { errorMessage, result } = await store.importIndustry(
          industryData
        );

        if (errorMessage) {
          const data: any = industryData;
          data.errorMessage = errorMessage;

          errorImportedData.value.push(data);
        }

        if (result) successImportedData.value.push(result);

        importedData++;
        useUiStateStore().createSnackbar({
          message: `${importedData}/${totalData} Industri telah diproses`
        });
      }

      isLoading.value = false;

      useUiStateStore().createSnackbar({
        message: `${
          successImportedData.value?.length ?? 0
        } berhasil & ${
          errorImportedData.value?.length ?? 0
        } gagal diimport`
      });
    };

    return {
      isMobile,
      loadedExcel,
      resultJson,
      convert,
      headers,
      search,
      isLoading,
      onSubmit,
      dataStartFromRow,
      successImportedData,
      errorImportedData,
      selectedDistrict,
      districtStore
    };
  }
});
